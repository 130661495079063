import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TermsGrid from '../../components/terms/terms-grid'
import PolicyDetails from '../../components/terms/trademark-disputes'

const TrademarkDisputes = ({ location }) => {
  return (
    <Layout isTallFooter>
      <SEO title='Trademark Disputes' pageType='home' flowType='none' />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default TrademarkDisputes
