import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  margin: 0 auto;
  padding: ${theme.space[5]}px;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
  color: ${theme.colors.gray};
  display: flex;
  flex-direction: column;
`
Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[4]}px 0;
  color: ${theme.colors.blackhighlight};
`

Policy.paragraph = styled.span`
  font-size: ${theme.font['md']};
  padding: ${theme.space[5]}px 0;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.blue};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.tealhighlight};
  }
`

export default Policy
