import React from 'react'
import Policy from './trademark-disputes.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const PolicyDetails = () => {
  return (
    <Policy>
      <Policy.title>Sitelio Trademark Disputes for Domain Names</Policy.title>
      <Policy.paragraph>
        If you have a dispute regarding your trademark being used within a
        domain name registered through us, please refer to ICANN’s Uniform
        Domain-Name Dispute-Resolution Policy ("UDRP") (
        <Policy.inlineLink
          data-element-location={DataElementLocations.BODY}
          data-element-label='uniform.domain.name.dispute'
          data-element-id='sitelio-trademark-domain-name-dispute'
          href='https://www.icann.org/resources/pages/help/dndr/udrp-en'
        >
          available here
        </Policy.inlineLink>
        ) for information about your options.
      </Policy.paragraph>
      <Policy.paragraph>
        If you have a complaint about the use of your trademark on a website
        hosted by us, please use the Report Member Violations form available{' '}
        <Policy.inlineLink to='../../abuse'>here</Policy.inlineLink>.
      </Policy.paragraph>
    </Policy>
  )
}

export default PolicyDetails
